import { Box } from "@mui/material";
import { Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Box sx={{ margin: 3 }}>
      <Typography>Access denied</Typography>
    </Box>
  );
}
